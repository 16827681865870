import Vue from 'vue'
import VueRouter from 'vue-router'
import Pipp from '../views/Pipp.vue'
import User from '../views/User.vue'
import FAQ from "../views/FAQ"
import SDKList from "../views/SDKList"
import FAQDetail from "../views/FAQ/FAQDetail"
import HomePage from "../views/HomePage"
import BusinessCooperation from "../views/BusinessCooperation.vue"
import businessLicense from "../views/businessLicense.vue"
import Course from "../views/Course.vue"
import ServiceAgreement from "../views/ServiceAgreement.vue"
import NodesToWithdrawal from "../views/NodesToWithdrawal.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta:{
      title:"小羊淘券"
    }
  },
  {
    path: '/home',
    name: 'HomePage',
    component: HomePage,
    meta:{
      title:"小羊淘券"
    }
  },
  {
    path: '/xiaoyang_shop/v1/pipp',
    name: 'Pipp',
    component: Pipp,
    meta:{
      title:"隐私政策"
    }
  },
  {
    path: '/xiaoyang_shop/v1/sdklist',
    name: 'sdklist',
    component: SDKList,
    meta:{
      title:"第三方SDK目录"
    }
  },
  {
    path: '/xiaoyang_shop/v1/user',
    name: 'User',
    component: User,
    meta:{
      title:"用户协议"
    }
  },
  {
    path: '/xiaoyang_shop/v1/business_cooperation',
    name: 'BusinessCooperation',
    component: BusinessCooperation,
    meta:{
      title:"商务合作"
    }
  },
  {
    path: '/xiaoyang_shop/v1/faq',
    name: 'FAQ',
    component: FAQ,
    meta:{
      title:"常见问题"
    }
  },
  {
    path: '/xiaoyang_shop/v1/faqdetail',
    name: 'FAQDetail',
    component: FAQDetail,
    meta:{
      title:"常见问题"
    }
  },
  {
    path: '/xiaoyang_shop/v1/business_license',
    name: 'businessLicense',
    component: businessLicense,
    meta:{
      title:"营业执照"
    }
  },
  {
    path: '/xiaoyang_shop/v1/course',
    name: 'Course',
    component: Course,
    meta:{
      title:"返利教程"
    }
  },
  {
    path: '/xiaoyang_shop/v1/serviceAgreement',
    name: 'serviceAgreement',
    component: ServiceAgreement,
    meta:{
      title:"运营商服务协议"
    }
  },
  {
    path: '/xiaoyang_shop/v1/nodesToWithdrawal',
    name: 'NodesToWithdrawal',
    component: NodesToWithdrawal,
    meta:{
      title:"提现须知"
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title; // 从路由元信息获取页面标题并设置到文档对象上
  } else {
    document.title = '小羊淘券'; // 若没有指定特定的页面标题，则使用默认值
  }
  
  next();
})

export default router
